<template>
  <div class="d-flex align-items-center justify-content-center vh-100">
    <h1 class="display-1 fw-bold">404 Not Found</h1>
  </div>
</template>

<script>
export default {
  layout: 'errorLayout',
};
</script>

<style></style>
